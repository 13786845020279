class TabSwitch{
    constructor(ele){
        this.index = 0;
        this.$ele = $(ele);

        this.$titleList = this.$ele.find('.tab-title-list');
        this.$titleItem = this.$titleList.children();
        this.$contentList = this.$ele.find('.tab-content-list');
        this.$contentItem = this.$contentList.children();
        let that = this;

        this.$titleItem.on('click',function () {
            let $this = $(this);
            let index = $this.index();
            if(index === that.getCurrentTabIndex()){
                return false;
            }
            that.updateTitleClass($this);
            that.updateShowTab(index);
            that.setActiveIndex(index);
        });
    }

    updateTitleClass($this){
        this.$titleItem.removeClass('active');
        $this.addClass('active');
    }

    getCurrentTabIndex(){
        return this.$ele.data('active-index') || 0;
    }

    updateShowTab(index){
        this.$contentItem.addClass('hidden').eq(index).removeClass('hidden');
    }

    setActiveIndex(index){
        this.$ele.data('active-index',index);
    }
}

$(function () {
    new TabSwitch('.tab-pannel');
});

//js 数组去重
// Array.prototype.filterRepeatData = function (field) {
//     field = field||'id';
//     var temObj = {};
//     var that = this;
//     angular.forEach(this,function (v,i) {
//         if(v[field] in temObj){
//             // temObj[v[field]]++;
//             that.splice(i,1);
//         }else{
//             temObj[v[field]] = 0;
//         }
//     });
//     return this;
// };

if(!window.utils){
    window.utils = {};
}


//获取活动的tab 下的元素
function getActiveTabEle(tabContentSelector,targetSelector) {
    let $tabContentSelector = $(tabContentSelector).not('.hidden');
    return $tabContentSelector.find(targetSelector);
}

//全选
function selectAll(ele, selectList){
    let $ele = $(ele),
        $selectList = $(selectList);
   if($ele.prop('checked')){
       $selectList.find('input[type="checkbox"]').prop('checked'

           ,true);
   }else{
       $selectList.find('input[type="checkbox"]').prop('checked',false);
   }
}

function getSelectIds(ele){
    let $ele = $(ele),
        $inputEle = null,
        res = [];
    $ele.forEach((i,v)=>{
        $inputEle = $(v).find('input[type="checkbox"]');
        if($inputEle.attr('checked')){
            res.push($inputEle.val());
        }
    });
    return res;
}

function cancelEnterKey(){
    $(window).on('keydown',(e)=>{
        if(e.keyCode === 13){
            return false;
        }
    });
}

function isEnterKey(e){
    return e.keyCode === 13;
}

function showMsg(info){
    $.msgBox({
        title: '提示',
        content: info
    })
}

window.utils.getActiveTabEle = getActiveTabEle;
window.utils.selectAll = selectAll;
window.utils.getSelectIds = getSelectIds;
window.utils.cancelEnterKey = cancelEnterKey;
window.utils.showMsg = showMsg;


// 图片幻灯片初始化
function initMagnificPopup(parentEle, childEle = '.popup-item') {
    $(parentEle).magnificPopup({
        delegate: childEle, // child items selector, by clicking on it popup will open
        type: 'image',
        gallery: {
            // options for gallery
            enabled: true
        },
        zoom: {
            enabled: true, // By default it's false, so don't forget to enable it

            duration: 300, // duration of the effect, in milliseconds
            easing: 'ease-in-out', // CSS transition easing function
        }
        // other options
    });
}


function initSwiper(ele = '',confObj = {}) {
    if(!Swiper || typeof Swiper != 'function'){
        return false;
    }
    var obj = {
        autoplay: 5000,//可选选项，自动滑动
        pagination : ele + ' .swiper-pagination',
        paginationClickable :true,
        nextButton: ele + ' .swiper-next',
        prevButton: ele + ' .swiper-prev',
        loop : true,
    };
    obj = $.extend(obj,confObj);
    var swiperInstance = new Swiper(ele, obj);
    return swiperInstance;
}

//计算图片比例
function scalePic(originWidth,originHeight,currentWidth) {
    let scale = currentWidth / originWidth;
    let currentHeight = originHeight * scale;
    return currentHeight;
}


//计算图片比例
function scalePicHeight(originWidth,originHeight,currentHeight) {
    let scale = currentHeight / originHeight;
    let currentWidth = originWidth * scale;
    return currentWidth;
}

window.utils.toFixedNumber = function(number,compareVal) {
    if(number < compareVal){
        number = String(number);
        compareVal = String(compareVal);
        let counter = compareVal.length - number.length;
        for(var i = counter;i > 0;i--){
            number = '0' + number;
        }
    }
    return number;
};

function getEventEmitter(name) {
    let eventInstance = window.EventEmitter[name];
    if(eventInstance){
        return eventInstance;
    }
    return window.EventEmitter[name] = new TinyEmitter();
}

function cutLength(str, length = 30) {
    if(str.length < length){
        return str;
    }
    str.length = length - 3;
    str += '...';
    return str;
}


if(!window.functions){
    window.functions = {};
}

window.functions.goBack = function () {
    window.history.go(-1);
};

window.functions.initLaydate = function(el,format = 'yyyy-MM-dd HH:mm:ss',defaultTime = '23:59:59',fnEnd = ()=>{}){
    if(!laydate){
        throw new Error('laydate is not loaded');
    }

    var year = new Date().getFullYear() + '',
        month = window.utils.toFixedNumber((new Date().getMonth() + 1),10) + '',
        day = window.utils.toFixedNumber(new Date().getDate(),10) + '',
        separatorYear = format[format.indexOf('yyyy') + 4],
        separatorMonth = format[format.indexOf('MM') + 2],
        separatorDay = format[format.indexOf('dd') + 2].trim() || '',
        currentDay = year + separatorYear + month + separatorMonth + day + separatorDay,
        isInitValue = !!$(el).val(),
        value = isInitValue ? $(el).val() : currentDay + ' ' + defaultTime;

    //执行一个laydate实例

    let instance = laydate.render({
        elem: el,
        format: format,
        type: 'datetime',
        value: value,
        isInitValue: isInitValue,
    });
    setTimeout(()=>{
        if(!isInitValue){ //isInitValue 配置不生效
            $(el).attr({
                value: ''
            }).val('');
        }
        fnEnd(el,instance);
    },150);

};

window.functions.getDateFormLaydate = function (dateObj) {
    return `${dateObj.year}/${dateObj.month}/${dateObj.date} ${dateObj.hours}:${dateObj.minutes}:${dateObj.minutes}`;
};

window.functions.getUnixTime = function (dateStr) {
    return new Date(Date.parse(dateStr)).getTime();
};

window.functions.initLaydateRange = function (layConf = {},intervalDay = 30) {
    let defConf = {
        elem: '',
        type: 'date',
        range: true,
        format: 'yyyy-MM-dd',
    },
    conf = Object.assign({},defConf,layConf),
    $el = $(layConf.elem),
    tmpDoneFn = conf.done || function(){},//防止默认的配置传入done方法导致覆盖
    intervalUnixTime = intervalDay * 3600 * 24 * 1000;

    conf.done = function (val,prevDate,nextDate) {
        tmpDoneFn(val,prevDate,nextDate);
        done(val,prevDate,nextDate);
    };

    function done(val,prevDate,nextDate){
        let prevDateUnixTime = window.functions.getUnixTime(window.functions.getDateFormLaydate(prevDate)),
            nextDateUnixTime = window.functions.getUnixTime(window.functions.getDateFormLaydate(nextDate)),
            selectedDiffUnixTime = nextDateUnixTime - prevDateUnixTime;
        if(selectedDiffUnixTime > intervalUnixTime){
            setTimeout(()=>{
                $el.val('').attr({
                    value: '',
                });
            });
            $.msgBox({
                title: '错误',
                content: `时间不能超过${intervalDay}天`,
                type: "error",
            });
        }
    }
    laydate.render(conf);
};


class autoSave{
    constructor (ele,obj = {}){
        this.$ele = $(ele);
        this.ele = ele;

        this.filterField = obj.filterField || ['__hash__'];
        this.autoSave = typeof obj.autoSave != 'undefined' ? obj.autoSave : 5000;
        this.autoAssign = typeof obj.autoAssign != 'undefined' ? obj.autoAssign : true;

        this.saveId = $.trim('autoSave-' + ele);

        this.init();

        return this;
    }

    init(){
        let that = this;

        if(!localStorage.getItem(this.saveId)){
            localStorage.setItem(this.saveId,'null');
        }

        if(this.autoSave){
            this.loopSave();
        }

        if(this.autoAssign){
            this.assign();
        }

    }

    loopSave(){
        setTimeout(() =>{
            this.save();
            this.loopSave();
        },this.autoSave);
    }

    save(){
        return new Promise((resolve)=>{
            let data = this.getSerializeFormData();
            localStorage.setItem(this.saveId,JSON.stringify(data));
            resolve(data);
        });
    }

    //获取数据  返回一个数组
    getData(attr = ''){
        let data = JSON.parse(localStorage.getItem(this.saveId));
        if(attr){
            let res = [];
            data.forEach((v)=>{
                if(v.name == attr){
                    res.push(v.value);
                }
            });
            return res;
        }
        return data;
    }

    //赋值
    assign(){
        let data = this.getData();
        if(!data){
            return false;
        }

        let tempObj = {};
        for(let v of data){
            if(this.filterField.indexOf(v.name) > -1){
                continue;
            }
            if(typeof tempObj[v.name] != 'number'){
                tempObj[v.name] = 0;
            }else{
                ++tempObj[v.name];
            }
            this.$ele.find(`*[name="${v.name}"]`).eq(tempObj[v.name]).val(v.value);
        }
    }

    getSerializeFormData() {
        return this.$ele.serializeArray();
    }
}

// 图片幻灯片初始化
function magnificPopupInit (parentEle,childEle) {
    if(typeof $.fn.magnificPopup === 'undefined'){
        return false;
    }
    var st=0;
    $(parentEle).each(function (){
        $(this).magnificPopup({
            delegate: childEle, // child items selector, by clicking on it popup will open
            type: 'image',
            gallery: {
                // options for gallery
                enabled: true
            },
            zoom: {
                enabled: true, // By default it's false, so don't forget to enable it

                duration: 300, // duration of the effect, in milliseconds
                easing: 'ease-in-out', // CSS transition easing function
            },
            // other options
            callbacks:{
                beforeOpen:function () {
                    st = $(document).scrollTop();
                    // $('body').css({'margin-top':'-'+st+'px','position':'fixed'});
                },
                open:function () {
                    // $('.mfp-wrap').css('top',st+'px');
                    if($(parentEle).hasClass('image-glally')){
                        $('.mfp-img').css({
                            'max-height': 'unset'
                        });

                        $('.mfp-arrow').on('click',function () {
                            $('.mfp-img').css({
                                'max-height': 'unset'
                            });
                        })
                    }
                },
                afterClose:function () {
                    // $('body').css({'margin-top':'auto','position':'static'});
                    $(document).scrollTop(st);
                }
            }
        });
    })
}

// let $donateCountItem = $('.select-label').children();
// $donateCountItem.on('click',function () {
//    let $this = $(this);
//     $donateCountItem.siblings().removeClass('on');
//    $this.addClass('on');
// });

//根据某个元素的数量  隐藏父级
function hideEmptyEl(childEl,parentEl) {
    let $childEl = $(childEl),
        $parentEl = $(parentEl),
        len = $childEl.length;
    if(len === 0){
        $parentEl.addClass('hidden');
    }
}

//进度圆
function drawProcessCircle(r,progress,el,speed,fnEnd){
    progress = parseFloat(progress);

    speed = speed || 700;

    fnEnd = fnEnd || function(){};

    var circumference = 2 * Math.PI * r,
        offset = circumference - Math.min(progress,100) / 100 * circumference,
        $el = $(el),
        $svg = $el.find('svg'),
        $processCircle = $el.find('.process-circle'),
        circleStrokeWidth = parseInt($processCircle.attr('stroke-width')),
        $count = $el.find('.process-count');


    $svg.attr({
        width: r * 2 + circleStrokeWidth,
        height: r * 2 + circleStrokeWidth,
    }).find('circle').attr({
        r: r,
        cy: circleStrokeWidth / 2 + r,
        cx: circleStrokeWidth / 2 + r,
        'stroke-width': circleStrokeWidth,
    });


    $processCircle.css({
        display: 'inline-block',
        'stroke-dasharray': circumference,
        'stroke-dashoffset': circumference,
    }).removeClass('hidden').animate({
        'stroke-dashoffset': offset,
    },speed,function(){
        fnEnd();
    });

    $count.attr({
        id: 'process-count-' + (Math.ceil(new Date().getTime() * Math.random()))
    });

    var countUp = new CountUp($count.attr('id'), 0, progress, 0, speed / 1000,{
        useEasing: true,
        useGrouping: true,
        decimal: '.',
        separator: ''
    });

    if (!countUp.error) {
        countUp.start();
    }
}

//根据点击的选项，显示相应的元素
function showRelativeFormItem(inputEle, relativeEle, activeVal = 0) {
    var val = Number($(inputEle + ':checked').val());
    var $relativeEle = $(relativeEle);
    if(val === activeVal){
        $relativeEle.removeClass('hidden');
    }else{
        $relativeEle.addClass('hidden');
    }
}


$(function () {
   $('.go-back').on('click',(e)=>{
       e.stopPropagation();
       window.functions.goBack();
   }) ;

    //模拟下拉框
    //  显示/隐藏
    $('.search-meta').on('click',function () {
        $(this).toggleClass('active').next('.search-pannel').slideToggle(200);
        $('.hide-search-pannel').toggle();
    });

    //项目点击事件
    let $searchList = $('.search-list');
    let $searchItem = $searchList.children();
    $searchItem.on('click',function () {
        $searchItem.removeClass('active');
        let $this = $(this);
        $this.toggleClass('active');
        // if($this.text()=='全部'){
        //     $('.select-item').text('');
        // }else{
        //     $('.select-item').text($this.text());
        // }
    });

    //全局关闭
    $('.hide-search-pannel').on('click',function () {
        $('.search-pannel').slideUp(200);
        $('.search-meta').removeClass('active');
        $(this).hide();
    });
    //模拟下拉框结束
});

//视频高度
var vodio = document.querySelectorAll('iframe');
vodio.forEach((item,index) => {
    item.style.height = item.scrollWidth * 0.75 + 'px';
});
// vodio.style.height = vodio.scrollWidth * 0.75 + 'px';
//计算图片比例
// function scalePic(originWidth,originHeight,currentWidth) {
//     let scale = currentWidth / originWidth;
//     let currentHeight = originHeight * scale;
//     return currentHeight;
// }
//
//
// //计算图片比例
// function scalePicHeight(originWidth,originHeight,currentHeight) {
//     let scale = currentHeight / originHeight;
//     let currentWidth = originWidth * scale;
//     return currentWidth;
// }

$(function () {
    $('.go-back').on('click', function () {
        window.history.back();
    });
    
    {
        let $popupMask = $('.popup-mask');
        if($popupMask.length > 1){
            for(let i = $popupMask.length; i > 0; i--){
                $popupMask.eq(i).remove();
            }
        }
    }
    
    {
        let $body = $('body');
        $body.on('click', '.toggle-siblings-active', function () {
            let $this = $(this);
            if($this.hasClass('active') || $this.hasClass('disabled')) return false;
            $this.addClass('active').siblings().removeClass('active');
        });
        
        $body.on('click', '.tips-fold',function () {
            var $this = $(this);
            var toggleData = $this.data('text');
            var currentTxt = $this.text();
            $this.data('text', currentTxt)
                .text(toggleData)
                .toggleClass('tips-fold-active');
        });
    
        $body.on('click', '.go-prev-page',function () {
            window.history.go(-1);
        })
    }

});